import Button from '@components/button/Button'
import Divider from '@components/divider/Divider'
import Logo, { NLogo } from '@components/logo/Logo'
import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './OutlookWelcome.module.css'
interface OutlookWelcomeProps {
  onClose: () => void
}

export const OutlookWelcome = (props: OutlookWelcomeProps) => {
  const { onClose } = props
  return (
    <div className={styles['welcome']}>
      <div className={styles['logo']}>
        <Logo />
      </div>
      <h2 className={styles['title']}>
        <span style={{ paddingLeft: '4px' }}>for Outlook</span>
      </h2>

      {/* <div className={styles['nlogo']}>
        <NLogo />
      </div> */}

      <div className={styles['description']}>
        <div className={styles['icon']}>
          <FontAwesomeIcon className={styles['mail']} icon={faEnvelope} />
        </div>
        <div className={styles['text']}>Associate outgoing emails with NextStage contacts and opportunities.</div>
      </div>
      <div className={styles['div']}>
        <Divider />
      </div>
      <div className={styles['how-it-works']}>
        How it works:
        <ol>
          <li>Sign in with your NextStage account.</li>
          <li>
            When you're composing an email, click the{' '}
            <span className={styles['small-icon']}>
              <FontAwesomeIcon className={styles['mail']} icon={faEnvelope} />
            </span>{' '}
            icon from the list of opportunities in your workspace.
          </li>
          <li>The plugin adds a unique tracking email to the BCC field.</li>
          <li>When you send the email, NextStage associates the email contents with the selected opportunity.</li>
        </ol>
      </div>

      <div className={styles['confirm']}>
        <Button btnType="lpCta" onClick={() => onClose()}>
          Get Started
        </Button>
      </div>
    </div>
  )
}

interface OutlookWelcomeModalProps {
  visible: boolean
  onClose: () => void
}
export const OutlookWelcomeModal = (props: OutlookWelcomeModalProps) => {
  const { visible, onClose } = props
  return (
    <FlyInModalPortal
      visible={visible}
      onClickOff={() => {
        onClose()
      }}
      dim
    >
      <OutlookWelcome onClose={onClose} />
    </FlyInModalPortal>
  )
}

import { AccountDNEMobilePrompt } from '@components/AccountDNEMobilePrompt/AccountDNEMobilePrompt'
import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import { Fragment } from 'react'

declare var Intercom: any

interface ScheduleDemoModalProps {
  visible: boolean
  onClose: () => void
}

export const ScheduleDemoModal = (props: ScheduleDemoModalProps) => {
  const { visible, onClose } = props

  return (
    <Fragment>
      <FlyInModalPortal
        visible={visible}
        dim={true}
        onClickOff={() => {
          onClose()
        }}
      >
        <AccountDNEMobilePrompt />
      </FlyInModalPortal>
    </Fragment>
  )
}

import { useGetMeQuery, useGetWorkspaceQuery } from '@interfaces/graphql'

import { useRouter } from 'next/router'
import { Fragment, useEffect } from 'react'
import { OutlookWelcomeWidget } from '../outlook/OutlookWelcomeWidget'
import { OutlookPluginLoading } from '../outlook/components/OutlookPluginLoading/OutlookPluginLoading'

interface EmbeddedLoginAutoRedirectProps {
  children: React.ReactNode
}

/**
 * If the user is already logged in, we need to redirect the user to the login result page with MODE=EMBEDDED
 * so that it will ping the hosting page to let it know that we're logged in.
 * @param props
 * @returns
 */
export const EmbeddedLoginAutoRedirect = (props: EmbeddedLoginAutoRedirectProps) => {
  const { children } = props

  const router = useRouter()

  // useGetMe will return even if MFA is not completed yet. We shouldn't redirect until MFA is completed.
  // const { data, loading } = useGetMeQuery({
  //   fetchPolicy: 'cache-and-network',
  // })

  const { data, loading } = useGetWorkspaceQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data?.getWorkspace) {
        // Redirecting to login result ping page to ping hosting page.
        router.push('/login/result?mode=EMBEDDED')
      }
    },
  })

  if (loading) {
    return (
      <div className="flexBoxCol" style={{ alignItems: 'center' }}>
        <OutlookPluginLoading />
      </div>
    )
  } else {
    return (
      <Fragment>
        <OutlookWelcomeWidget />
        {children}
      </Fragment>
    )
  }
}

import Button from '@components/button/Button'
import useIsMobile from '@helpers/hooks/IsMobileHook'
import { AuthMode, useGetAuthInfoQuery } from '@interfaces/graphql'
import AcceptTermsAndPrivacy from '@partials/AcceptTermsAndPrivacy'
import { setSignUpSlice } from '@redux/reducers/SignUpSlice'
import { RootState } from '@src/redux/create-store'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScheduleDemoModal } from './ScheduleDemoModal'
import PasswordLogin from './PasswordLogin'
import Link from 'next/link'
import SSOAuth from './SSOAuth'
import { TextInput } from './TextInput'
import { trackEvent } from '@helpers/analytics/trackEvent'

interface FormValues {
  email: string
}

interface LoginFlowProps {
  error?: string
  embedded?: boolean
}

const LoginFlow = ({ error, embedded = false }: LoginFlowProps) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const signUpState = useSelector((state: RootState) => state.signUp)

  const { data, loading, refetch, variables } = useGetAuthInfoQuery({
    // Embedded mode is forced to use external flow
    variables: {
      mode: embedded ? AuthMode.External : AuthMode.Login,
      redirect: (router.query.redirect as string) ?? undefined,
    },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
  })

  const handleClickNext = async (values: FormValues) => {
    let hasAnalytics = false
    try {
      window.analytics.identify({
        email: values.email,
      })
      trackEvent('Clicked Log In')
    } catch {}

    try {
      ;(window as any)?.analytics?.user()?.anonymousId()
      hasAnalytics = true
    } catch (err) {}

    refetch({
      mode: AuthMode.Login,
      email: values.email,
      anonymousId: hasAnalytics ? (window as any).analytics.user().anonymousId() : undefined,
    })
  }

  const showPasswordLogin = data?.getAuthInfo?.isExistingAccount && data?.getAuthInfo?.allowPasswordAuth

  let accountDoesNotExist =
    data && variables?.email && variables?.email == signUpState.email && data?.getAuthInfo?.isExistingAccount == false

  const isMobile = useIsMobile()

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false)

  useEffect(() => {
    if (accountDoesNotExist && isMobile) {
      setIsScheduleModalVisible(true)
    }
  }, [accountDoesNotExist])

  if (data) {
    return (
      <Fragment>
        <ScheduleDemoModal
          visible={isScheduleModalVisible}
          onClose={() => {
            setIsScheduleModalVisible(false)
            refetch({
              mode: AuthMode.Login,
              email: '',
            })
          }}
        />
        {data?.getAuthInfo && (
          <SSOAuth
            noLogo={embedded}
            authUrls={data.getAuthInfo.authUrls}
            onClick={(url) => {
              // Embedded mode is forced to use external flow
              if (embedded) {
                const q = new URLSearchParams()
                q.set('url', url.url)
                router.push('/login/sso?' + q.toString())
              } else {
                router.push(url.url)
              }
            }}
          />
        )}

        <p style={{ margin: '0.5rem auto' }}>Or</p>

        {showPasswordLogin ? (
          <PasswordLogin embedded={embedded} />
        ) : (
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault()
              if (signUpState.email) {
                handleClickNext({
                  email: signUpState.email,
                })
              }
            }}
          >
            <TextInput
              id="email"
              label="Email"
              type="email"
              name="email"
              className="input"
              autoComplete="email"
              value={signUpState?.email || ''}
              placeholder="Business Email"
              onChange={(e) => {
                dispatch(setSignUpSlice({ ...signUpState, email: e.target.value }))
              }}
            />
            {accountDoesNotExist && !embedded && (
              <div>
                No account matching this email.{' '}
                <span className="link" onClick={() => router.push('/signup', '/signup', { shallow: true })}>
                  Sign Up
                </span>
              </div>
            )}
            {error && <span className="red margin-top-05">{error}</span>}
            <Button
              type="submit"
              onClick={() => {
                if (signUpState.email) {
                  handleClickNext({
                    email: signUpState.email,
                  })
                }
              }}
              disabled={!!accountDoesNotExist}
            >
              Next
            </Button>
            <Link href="/forgot">
              <div className="blue hover-blue margin-top-05">
                Having trouble accessing your account? Reset your password
              </div>
            </Link>
            <AcceptTermsAndPrivacy />
          </form>
        )}
      </Fragment>
    )
  }

  return null
}

export default LoginFlow

import { MFA } from '@components/auth/mfa'
import Button from '@components/button/Button'
import { TextInput } from '@components/form/Form'
import { AuthUrl, GetMeDocument, useLoginMutation } from '@interfaces/graphql'
import AcceptTermsAndPrivacy from '@partials/AcceptTermsAndPrivacy'
import { setSignUpSlice } from '@redux/reducers/SignUpSlice'
import { setUser as setReduxUser } from '@redux/reducers/UserSlice'
import { useSelector } from '@redux/use-selector'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { NextRouter, useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import SSOAuth from './SSOAuth'

function getErrorMsg(error?: Error): string {
  if (error?.toString() == 'Error: GraphQL error: Invalid password') {
    return 'Invalid password, please try again'
  }

  if (error?.toString() == 'Error: GraphQL error: No password') {
    return 'This account must authenticate through Google or Microsoft.'
  }

  if (error?.toString().startsWith('Error: GraphQL error: No user found for email')) {
    return 'No account with that email found'
  }

  if (error?.toString().startsWith('Error: GraphQL error: Login method not enabled')) {
    return 'Login method is not enabled on your workspace'
  }

  console.log('error', error)

  return 'An error occurred, please try again'
}

function onRedirect(router: NextRouter, embedded: boolean, redirect?: string) {
  const q = new URLSearchParams()
  q.set('mode', embedded ? 'EMBEDDED' : 'LOGIN')
  q.set('redirect', redirect || '/pipeline')
  router.push(`/login/result?${q.toString()}`)
}

export const onEmbeddedSupportRedirectToLogin = onRedirect

interface PasswordLoginPromptProps {
  email?: string
  authUrls?: AuthUrl[]
  embedded?: boolean

  // This is called when MFA is required.
  onMFA?: () => void
}
const PasswordLoginPrompt = (props: PasswordLoginPromptProps) => {
  const { email, authUrls, embedded = false, onMFA } = props

  const signUpState = useSelector((state) => state.signUp)
  const dispatch = useDispatch()
  const router = useRouter()
  const redirect = router.query.redirect as string

  const [login, { error }] = useLoginMutation({
    // Do not remove. We need to wait for the user to log in before updating GetMeDocument
    awaitRefetchQueries: true,
    // We refetch this query after the user signs up so that _app.tsx knows the user is authenticated
    // Attention! Very important that frontend/src/partials/login/EmbeddedLoginAutoRedirect.tsx
    // only redirects after MFA is complete!
    refetchQueries: [GetMeDocument],
    onCompleted: (results) => {
      if (results.login?.user?.workspace?.enforceMfa) {
        if (onMFA) {
          onMFA()
        } else {
          router.push('/verify')
        }
      } else if (results?.login?.user) {
        onRedirect(router, embedded, redirect)
      }
    },
  })

  return (
    <Formik
      initialValues={{ email: email, password: '' }}
      onSubmit={async (values, { setSubmitting }) => {
        await login({
          variables: {
            // TODO: Fix type
            email: signUpState.email?.toLowerCase() as any,
            password: values.password,
          },
        })

        setSubmitting(false)
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="form">
          {authUrls && (
            <SSOAuth
              authUrls={authUrls}
              onClick={(url) => {
                router.push(url.url)
              }}
            />
          )}

          <TextInput
            type="email"
            name="email"
            autoComplete="email"
            label="Email"
            value={signUpState?.email}
            placeholder="Email"
            onChange={(e) => {
              setFieldValue('email', e.target.value)
              dispatch(setSignUpSlice({ ...signUpState, email: e.target.value }))
            }}
          />
          <TextInput
            type="password"
            autoFocus
            name="password"
            autoComplete="current-password"
            label="Password"
            placeholder="Password"
          />
          {error ? <span className="red">{getErrorMsg(error)}</span> : null}

          <AcceptTermsAndPrivacy />

          <Button
            customStyles={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            type="submit"
            btnType="primary"
            loading={isSubmitting}
          >
            Log In
          </Button>

          <Link href="/forgot">
            <div className="blue hover-blue margin-top-05" style={{ textAlign: 'center' }}>
              Having trouble accessing your account? Reset your password
            </div>
          </Link>
        </Form>
      )}
    </Formik>
  )
}

/**
 * This component supports a redirect query parameter.
 */
interface MFAPromptProps {
  embedded?: boolean
}
export const MFAPrompt = (props: MFAPromptProps) => {
  const { embedded = false } = props

  const router = useRouter()
  const redirect = router.query.redirect as string

  console.log('MFA Prompt with redirect', redirect)
  const onMFASuccess = useCallback(() => {
    onRedirect(router, embedded, redirect)
  }, [router, embedded, redirect])

  return (
    <MFA
      title={'Almost there!'}
      method={'Email'}
      message={
        "In order to use NextStage, you'll need to verify your email. We'll send you a one-time code to your email. Click the button below to continue."
      }
      sendButton={true}
      showOptions={false}
      onSuccess={onMFASuccess}
    />
  )
}

type PasswordLoginProps = {
  email?: string
  authUrls?: AuthUrl[]
  embedded?: boolean
}

const PasswordLogin = (props: PasswordLoginProps) => {
  // If login requires 2fa, then switch state to 2fa.
  const [pageState, setPageState] = useState<'login' | '2fa'>('login')

  const onMFA = useCallback(() => {
    setPageState('2fa')
  }, [])

  if (pageState === 'login') {
    return <PasswordLoginPrompt {...props} onMFA={onMFA} />
  } else {
    return <MFAPrompt {...props} />
  }
}

export default PasswordLogin

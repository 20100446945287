import { initApolloClient } from '@apolloLocal/apollo'
import Navigation from '@components/landingpage/Navigation'
import { isMobile } from '@helpers/hooks/IsMobileHook'
import { GetWorkspaceLogosDocument } from '@interfaces/graphql'
import { EmbeddedLoginAutoRedirect } from '@src/partials/login/EmbeddedLoginAutoRedirect'
import { LoginForm } from '@src/partials/login/LoginForm'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import Head from 'next/head'
import { Fragment } from 'react'

interface LoginPageProps {
  error?: string
  primaryLogo?: string

  // Note: This boolean may be written by a query param, but may also be overwritten by _app.tsx
  isEmbedded?: boolean
}

const LoginPage = ({ primaryLogo, error, isEmbedded }: LoginPageProps) => {
  if (isEmbedded) {
    return (
      <div style={{ marginTop: '4em' }}>
        <EmbeddedLoginAutoRedirect>
          <LoginForm error={error} primaryLogo={primaryLogo} signUpMode="external" embedded />
        </EmbeddedLoginAutoRedirect>
      </div>
    )
  } else {
    return (
      <Fragment>
        <Navigation notFixed />

        <div className="flexBoxCol container--center" style={{ marginTop: '4em' }}>
          <Head>
            <title>NextStage | Login</title>
          </Head>

          <LoginForm error={error} primaryLogo={primaryLogo} />
        </div>
      </Fragment>
    )
  }
}

/**
 * Query:
 * 'redirect' query param is available despite not being inspected in the function below
 *    see PasswordLogin.tsx
 *  For SSO, the redirect query is included in the state when redirecting to the SSO provider.
 *  When the SSO provider returns to NextStage, that redirect is again unpacked from the state.
 *    see createWorkOSAuthApp.ts
 *
 * @param ctx
 * @returns
 */
export async function getServerSideProps(
  ctx: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<LoginPageProps>> {
  const { query, req } = ctx
  const apolloClient = initApolloClient(ctx)

  const { workspaceHumanId, error = null, embedded } = query

  const isEmbedded = embedded === 'true'

  if (req) {
    console.log('UA: ', req.headers['user-agent'])
    if (isMobile(req.headers['user-agent'])) {
      console.log('Mobile!')
    }
  }

  let logos
  if (workspaceHumanId?.[0]) {
    let { data } = await apolloClient.query({
      query: GetWorkspaceLogosDocument,
      fetchPolicy: 'network-only',
      variables: {
        workspaceHumanId: workspaceHumanId?.[0],
      },
    })

    logos = data?.getWorkspaceLogos
  }

  return {
    props: {
      ...(logos?.sidebarLogo && {
        sidebarLogo: `https://nextstage.ai/images/${logos?.sidebarLogo}`,
      }),
      ...(logos?.primaryLogo && {
        primaryLogo: `https://nextstage.ai/images/${logos?.primaryLogo}`,
      }),
      error: error,
      isEmbedded: isEmbedded,
    },
  }
}

export default LoginPage

import Image from '@components/image/Image'
import Logo from '@components/logo/Logo'
import LoginFlow from '@partials/auth/LoginFlow'
import Link from 'next/link'
import styles from './LoginForm.module.scss'
interface LoginFormProps {
  error?: string
  primaryLogo?: string

  signUpMode?: 'none' | 'default' | 'external'
  embedded?: boolean
}

export const LoginForm = (props: LoginFormProps) => {
  const { primaryLogo, error, signUpMode = 'default', embedded = false } = props
  return (
    <div className={styles['container'] + ' flexBoxCol'}>
      <div style={{ margin: '0 auto' }}>
        {primaryLogo ? (
          <img
            className={styles['big-logo']}
            alt="NextStage Logo"
            src={primaryLogo || '/images/logo/NLogo.svg'}
            width={400}
            height={200}
          />
        ) : (
          <Logo noLink={embedded} className={styles['big-logo']} />
        )}
      </div>

      <h2 style={{ margin: '0.5rem auto' }}>Log In</h2>

      <LoginFlow error={error} embedded={embedded} />

      {signUpMode !== 'none' && (
        <Link href="/signup">
          <a
            className="blue hover-blue margin-top-05"
            style={{ textAlign: 'center' }}
            target={signUpMode === 'external' ? '_blank' : undefined}
          >
            Don't have an account? Sign up
          </a>
        </Link>
      )}
    </div>
  )
}

export const TextInput = ({ label, type = 'text', ...props }) => {
  return (
    <>
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className="text-input" aria-label type={type} {...props} />
    </>
  )
}

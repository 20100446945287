import { useContext, useEffect, useState } from 'react'
import { EmbeddedAPIContext } from '../embedded/EmbeddedAPIContext'
import { OutlookWelcomeModal } from './OutlookWelcome'

interface OutlookWelcomeWidgetProps {}

export const OutlookWelcomeWidget = (props: OutlookWelcomeWidgetProps) => {
  const {} = props

  const outlook = useContext(EmbeddedAPIContext)

  const [showOutlookWelcome, setShowOutlookWelcome] = useState(false)

  useEffect(() => {
    outlook.eventWelcomeReady()
  }, [])

  useEffect(() => {
    const onMessage = (msg) => {
      if (msg.data?.type === 'NextStageEmbedded: Show-Welcome') {
        setShowOutlookWelcome(true)
      }
    }

    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return <OutlookWelcomeModal visible={showOutlookWelcome} onClose={() => setShowOutlookWelcome(false)} />
}

import { Fragment } from 'react'
import styles from './AccountDNEMobilePrompt.module.scss'
import { ScheduleADemoCTAButton } from '@src/partials/landingpage/ScheduleADemoCTAButton'

/**
 * Account {Does Not Exist} Mobile Prompt
 *
 * @param onClose if onClose is available, 'go back' will be available at the bottom
 * @returns
 */
export const AccountDNEMobilePrompt = () => {
  return (
    <Fragment>
      <div className={styles['container']}>
        <h2 className={styles['logo']}>See NextStage in Action</h2>

        <div className={styles['blurb']}>Learn how to NextStage helps you find and win government contracts.</div>

        <div className={styles['cta']}>
          <ScheduleADemoCTAButton />
        </div>
      </div>
    </Fragment>
  )
}
